import React, { useState, useEffect } from 'react';
import { Modal, FormControl, MenuItem, Select, Box, Typography, Tabs, Tab, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CheckList } from '../../Model/CheckList';
import { CheckListIndicator } from '../../Model/CheckListIndicator';
import { CheckListSpecificIndicator } from '../../Model/CheckListSpecificIndicator';
import { CheckListRate } from '../../Model/CheckListRate';
import { User } from '../../Model/User';
import { ApiUrl } from '../../ApiUrl';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import appColor from '../../Style/appColor';
import { CheckListSubject } from '../../Model/CheckListSubject';
import { useParams } from "react-router";
import { CheckListStatus } from '../../Model/Enum/CheckListStatus';
import { useAuth } from "../../Hooks/AuthProvider";
function CustomToolbar() {
    return (
        <GridToolbarContainer>
        </GridToolbarContainer>
    );
}

function EditCheckListPage() {
    const { checkListId } = useParams();
    const auth = useAuth();
    const navigate = useNavigate();
    const navigateBack = () => navigate('/libraries-standard');

    const [isCheckListSaving, setIsCheckListSaving] = useState(false);

    const [selectedTab, setSelectedTab] = useState(1);

    // CheckList modals
    const [isCheckListIndicatorModalOpen, setIsCheckListIndicatorModalOpen] = useState(false);
    const [isCheckListSpecificIndicatorModalOpen, setIsCheckListSpecificIndicatorModalOpen] = useState(false);
    const [isCheckListRateModalOpen, setIsCheckListRateModalOpen] = useState(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [isWarningIndicatorRowModalOpen, setIsWarningIndicatorRowModalOpen] = useState(false);

    const [isCheckListIndicatorModalEditOpen, setIsCheckListIndicatorModalEditOpen] = useState(false);
    const [isCheckListSpecificIndicatorModalEditOpen, setIsCheckListSpecificIndicatorModalEditOpen] = useState(false);
    const [isCheckListRateModalEditOpen, setIsCheckListRateModalEditOpen] = useState(false);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteSpecificModalOpen, setIsDeleteSpecificModalOpen] = useState(false);
    const [deleteSelectedIndicatorId, setDeleteSelectedIndicatorId] = useState(0);
    const [deleteSelectedSpecificIndicatorId, setDeleteSelectedSpecificIndicatorId] = useState(0);

    const handleDeleteModalOpen = (row) => { setIsDeleteModalOpen(true); setDeleteSelectedIndicatorId(row.id); }
    const handleDeleteModalClose = () => { setIsDeleteModalOpen(false); setDeleteSelectedIndicatorId(0); }
    const handleDeleteSpecificModalOpen = (row) => { setIsDeleteSpecificModalOpen(true); setDeleteSelectedSpecificIndicatorId(row.id); }
    const handleDeleteSpecificModalClose = () => { setIsDeleteSpecificModalOpen(false); setDeleteSelectedSpecificIndicatorId(0); }

    
    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleCheckListIndicatorModalOpen = () => { setIsCheckListIndicatorModalOpen(true); calculateFormIndicatorWeight(); }
    const handleCheckListIndicatorModalClose = () => {
        setIsCheckListIndicatorModalOpen(false);
        setIsCheckListIndicatorModalEditOpen(false);
        clearCheckListIndicatorForm();
    }

    const handleCheckListSpecificIndicatorModalOpen = () => { if(indicatorIdSelectedRow === 0) { setIsWarningIndicatorRowModalOpen(true); return; } setIsCheckListSpecificIndicatorModalOpen(true); calculateFormSpecificIndicatorWeight(); }
    const handleCheckListSpecificIndicatorModalClose = () => {
        setIsCheckListSpecificIndicatorModalOpen(false);
        setIsCheckListSpecificIndicatorModalEditOpen(false);
        clearCheckListSpecificIndicatorForm();
    }

    const handleCheckListRateModalOpen = () => {
        if(indicatorIdSelectedRow === 0) { setIsWarningIndicatorRowModalOpen(true); return; }
        setIsCheckListRateModalOpen(true);
        const checkListRate = checkListRateList.filter(rate => rate.checkListSpecificIndicator === undefined ? rate.checkListIndicator.id === indicatorIdSelectedRow : rate.checkListSpecificIndicator.id === specificIndicatorIdSelectedRow);
        setFormRateRate(checkListRate.length + 1);
    }

    const handleCheckListRateModalClose = () => {
        setIsCheckListRateModalOpen(false);
        setIsCheckListRateModalEditOpen(false);
        clearCheckListRateForm();
    }

    const handleWarningModalClose = () => setIsWarningModalOpen(false);
    const handleWarningIndicatorRowModalClose = () => setIsWarningIndicatorRowModalOpen(false);

    const calculateFormIndicatorWeight = () => {
        if(checkListIndicatorList.length + 1 === 1) {
            setFormWeight(100);
        } else {
            const formWeight = Math.round((1 / (checkListIndicatorList.length + 1)) * 100);
            setFormWeight(formWeight);
        }
    }

    const calculateFormSpecificIndicatorWeight = () => {
        if(checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === indicatorIdSelectedRow).length + 1 === 1) {
            setFormSpecificWeight(100);
        } else {
            const formWeight = Math.round((1 / (checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === indicatorIdSelectedRow).length + 1)) * 100);
            setFormSpecificWeight(formWeight);
        }
    }

    // CheckList components
    const [checkListIndicatorList, setCheckListIndicatorList] = useState([]);
    const [checkListSpecificIndicatorList, setCheckListSpecificIndicatorList] = useState([]);
    const [checkListRateList, setCheckListRateList] = useState([]);

    // CheckList form fields
    const [formGlobalCode, setFormGlobalCode] = useState("");
    const [formGlobalCreatedBy, setFormGlobalCreatedBy] = useState("");
    const [formGlobalSubject, setFormGlobalSubject] = useState("");
    const [formGlobalDescription, setFormGlobalDescription] = useState("");
    const [checkListSelectedStatus, setCheckListSelectedStatus] = useState("");
    const [formGlobalSubjectList, setFormGlobalSubjectList] = useState([]);
    const [checkListStatuses, setCheckListStatuses] = useState([]);
    
    const [formId, setFormId] = useState("");
    const [formItem, setFormItem] = useState("");
    const [formDescription, setFormDescription] = useState("");
    const [formNumberOfRatings, setFormNumberOfRatings] = useState("");
    const [formWeight, setFormWeight] = useState(0);

    const [formSpecificId, setFormSpecificId] = useState("");
    const [formSpecificItem, setFormSpecificItem] = useState("");
    const [formSpecificDescription, setFormSpecificDescription] = useState("");
    const [formSpecificNumberOfRatings, setFormSpecificNumberOfRatings] = useState("");
    const [formSpecificWeight, setFormSpecificWeight] = useState(0);

    const [formRateId, setFormRateId] = useState("");
    const [formRateRate, setFormRateRate] = useState("");
    const [formRateDescription, setFormRateDescription] = useState("");
    const [formRateExtendedDescription, setFormRateExtendedDescription] = useState("");

    // Selected rows
    const [indicatorIdSelectedRow, setIndicatorIdSelectedRow] = useState(0);
    const [specificIndicatorIdSelectedRow, setSpecificIndicatorIdSelectedRow] = useState(0);

    // CheckList form validations
    const [formGlobalCodeError, setFormGlobalCodeError] = useState(false);
    //const [formGlobalSubjectError, setFormGlobalSubjectError] = useState("");
    const [formGlobalDescriptionError, setFormGlobalDescriptionError] = useState(false);
    
    const [formItemError, setFormItemError] = useState(false);
    const [formDescriptionError, setFormDescriptionError] = useState(false);
    const [formNumberOfRatingsError, setFormNumberOfRatingsError] = useState(false);

    const [formSpecificItemError, setFormSpecificItemError] = useState(false);
    const [formSpecificDescriptionError, setFormSpecificDescriptionError] = useState(false);
    const [formSpecificNumberOfRatingsError, setFormSpecificNumberOfRatingsError] = useState(false);

    const [formRateRateError, setFormRateRateError] = useState(false);
    const [formRateDescriptionError, setFormRateDescriptionError] = useState(false);
    const [formRateExtendedDescriptionError, setFormRateExtendedDescriptionError] = useState(false);
    
    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const[typeAlert, setTypeAlert] = useState("");
    const[messageAlert, setMessageAlert] = useState("");

    const getCheckListSubjectList = () => {
        fetch(ApiUrl.subjectEndpoint + "/Type/2")
            .then(response => response.json())
            .then(json => setFormGlobalSubjectList(json))
            .catch(error => console.error(error));
    }

    const getCheckListById = () => {
        fetch(ApiUrl.checkListEndpoint + "/" + checkListId)
            .then(response => response.json())
            .then(json => {
                setFormGlobalCode(json.code);
                setFormGlobalSubject(json.subject.id);
                setCheckListSelectedStatus(CheckListStatus[json.status]);
                setFormGlobalDescription(json.description);
                setFormGlobalCreatedBy(json.ownerUser.name + " " + json.ownerUser.surname);
            })
            .catch(error => console.error(error));
    }

    function getAllCheckListStatus() {
        fetch(ApiUrl.checkListEndpoint + "/CheckListStatus")
            .then(response => response.json())
            .then(json => setCheckListStatuses(json))
            .catch(error => console.error(error));
    }

    const getCheckListRateByCheckListIndicator = (checkListIndicatorId) => {
        fetch(ApiUrl.checkListRateEndpoint + "/GetAllByCheckListIndicator/" + checkListIndicatorId)
            .then(response => response.json())
            .then(json => {
                if(json.length > 0) {
                    const tempCheckListRate = json.reduce((acc, current) => {
                        const exists = acc.some(item => item.id === current.id);
                        return exists ? acc : [...acc, current];
                    }, [])
                    tempCheckListRate.map((checkListRate) => checkListRateList.push(checkListRate))
                }
            })
            .catch(error => console.error(error));
    }

    const getCheckListRateByCheckListSpecificIndicator = (checkListSpecificIndicatorId) => {
        if (checkListSpecificIndicatorId === undefined) { return; }
        fetch(ApiUrl.checkListRateEndpoint + "/GetAllByCheckListSpecificIndicator/" + checkListSpecificIndicatorId)
            .then(response => response.json())
            .then(json => {
                if(json.length > 0) {
                    const tempCheckListRate = json.reduce((acc, current) => {
                        const exists = acc.some(item => item.id === current.id);
                        return exists ? acc : [...acc, current];
                    }, [])
                    tempCheckListRate.map((checkListRate) => checkListRateList.push(checkListRate))
                }
            })
            .catch(error => console.error(error));
    }

    const getCheckListSpecificIndicatorByCheckListIndicator = (checkListIndicatorId) => {
        fetch(ApiUrl.checkListSpecificIndicatorEndpoint + "/GetAllByCheckListIndicator/" + checkListIndicatorId)
            .then(response => response.json())
            .then(json => {
                if(json.length > 0) {
                    const tempCheckListSpecificIndicator = json.reduce((acc, current) => {
                        const exists = acc.some(item => item.id === current.id);
                        return exists ? acc : [...acc, current];
                    }, [])
                    tempCheckListSpecificIndicator.map((specificIndicator) => checkListSpecificIndicatorList.push(specificIndicator));
                    json.forEach((specificIndicator) => {
                        getCheckListRateByCheckListSpecificIndicator(specificIndicator.id);
                    });
                }
            })
            .catch(error => console.error(error));
    }

    const getCheckListIndicatorByCheckList = () => {
        fetch(ApiUrl.checkListIndicatorEndpoint + "/GetAllByCheckList/" + checkListId)
            .then(response => response.json())
            .then(json => {
                setCheckListIndicatorList(json);
                json.forEach((indicator) => {
                    getCheckListRateByCheckListIndicator(indicator.id);
                    getCheckListSpecificIndicatorByCheckListIndicator(indicator.id);
                });
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getAllCheckListStatus();
        getCheckListSubjectList();
        getCheckListById();
        getCheckListIndicatorByCheckList();
    }, []);

    const handleCheckListSave = async () => {
        setLoadingButton(true);
        const globalCheckList = new CheckList();
        globalCheckList.id = checkListId;

        const entries = Object.entries(CheckListStatus);
        const foundEntry = entries.find(([_, value]) => value === checkListSelectedStatus);

        globalCheckList.status = parseInt(foundEntry[0]);
        globalCheckList.type = 2;

        const checkListSubject = new CheckListSubject();
        checkListSubject.id = formGlobalSubject;
        
        globalCheckList.subject = checkListSubject;
        globalCheckList.code = formGlobalCode;
        globalCheckList.description = formGlobalDescription;
        if (auth) {
            globalCheckList.modifiedBy = auth.user?.id;
        }
        const profile =  new User();
        profile.id = 4;
        globalCheckList.ownerUser = profile;


        let isCodeError = false;
        let isDescriptionError = false;

        if(!formGlobalCode.trim()) {
            setFormGlobalCodeError(true);
            isCodeError = true;
        } else {
            setFormGlobalCodeError(false);
            isCodeError = false;
        }

        if(!formGlobalDescription.trim()) {
            setFormGlobalDescriptionError(true);
            isDescriptionError = true;
        } else {
            setFormGlobalDescriptionError(false);
            isDescriptionError = false;
        }

        if(isCodeError || isDescriptionError) {
            return;
        }

        if (checkListIndicatorList.length === 0 || checkListRateList.length === 0) {
            setIsWarningModalOpen(true);
            return;
        } else if (checkListSpecificIndicatorList.length > 0 && checkListRateList.length === 0) {
            setIsWarningModalOpen(true);
            return;
        }

        setIsCheckListSaving(true);
        fetch(ApiUrl.checkListEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(globalCheckList),
        })
        .then(response => response.json())
        .then(_ =>
            checkListIndicatorList.forEach((indicator) => {
                const checklist = new CheckList();
                checklist.id = checkListId;
                indicator.checklist = checklist;
                if (auth) {
                    indicator.modifiedBy = auth.user?.id;
                }
                const oldId = indicator.id;

                if(indicator.isNew !== undefined && indicator.isNew) {
                    indicator.id = 0;
                }

                fetch(ApiUrl.checkListIndicatorEndpoint, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(indicator),
                })
                .then(response => response.json())
                .then(indicatorData => {
                    indicator.id = oldId;
                    checkListSpecificIndicatorList.forEach((specificIndicator) => {
                        if(specificIndicator.checkListIndicator !== undefined && specificIndicator.checkListIndicator.id === indicator.id) {
                            specificIndicator.checkListIndicator = new CheckListIndicator();
                            specificIndicator.checkListIndicator.id = indicatorData.id;
                            if (auth) {
                                specificIndicator.modifiedBy = auth.user?.id;
                            }
                            const spOldId = specificIndicator.id;

                            if(specificIndicator.isNew !== undefined && specificIndicator.isNew) {
                                specificIndicator.id = 0;
                            }

                            fetch(ApiUrl.checkListSpecificIndicatorEndpoint, {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(specificIndicator),
                            })
                            .then(response => response.json())
                            .then(specificIndicatorData => {
                                specificIndicator.id = spOldId;
                                checkListRateList.forEach((rate) => {
                                    if(rate.checkListSpecificIndicator !== undefined && rate.checkListSpecificIndicator.id === specificIndicator.id) {
                                        rate.checkListIndicator = null;
                                        rate.checkListSpecificIndicator = new CheckListSpecificIndicator();
                                        rate.checkListSpecificIndicator.id = specificIndicatorData.id;
                                        if (auth) {
                                            rate.modifiedBy = auth.user?.id;
                                        }
                                        if(rate.isNew !== undefined && rate.isNew) {
                                            rate.id = 0;
                                        }
            
                                        fetch(ApiUrl.checkListRateEndpoint, {
                                            method: 'PUT',
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify(rate),
                                        })
                                        .catch(error => {
                                            console.log(error);
                                            setOpen(true);
                                            setTypeAlert("error");
                                            setMessageAlert("Error method put rate");
                                        });
                                    }
                                })
                            })
                            .catch(error => {
                                console.log(error);
                                setOpen(true);
                                setTypeAlert("error");
                                setMessageAlert("Error method put specificIndicator");
                            });
                        }
                    })

                    checkListRateList.forEach((rate) => {
                        indicator.id = oldId;
                        if(rate.checkListIndicator !== undefined && rate.checkListIndicator.id === indicator.id) {
                            rate.checkListIndicator = new CheckListIndicator();
                            rate.checkListIndicator.id = indicatorData.id;
                            if (auth) {
                                rate.modifiedBy = auth.user?.id;
                            }
                            if(rate.isNew !== undefined && rate.isNew) {
                                rate.id = 0;
                            }

                            fetch(ApiUrl.checkListRateEndpoint, {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(rate),
                            })
                            .catch(error => {
                                console.log(error);
                                setOpen(true);
                                setTypeAlert("error");
                                setMessageAlert("Error updating checklist");
                                setLoadingButton(false);
                            })
                        }
                    })
                })
                .catch(error => {
                    console.log(error);
                    setOpen(true);
                    setTypeAlert("error");
                    setMessageAlert("Error method put indicator");
                });
            })
        ).then(() => {
            setTimeout(() => {
                navigateBack();
            }, 2000);
        });
        
        setOpen(true);
        setTypeAlert("success");
        setMessageAlert("Checklist added successfully! Wait to go back");
        setTimeout(() => {
            setLoadingButton(false);
        }, 2000);
    }

    const handleCheckListIndicatorSave = async () => {
        try {          
            let isItemError = false;
            let isDescriptionError = false;
            let isNumberOfRatingsError = false;
    
            if(!formItem.trim()) {
                setFormItemError(true);
                isItemError = true;
            } else {
                setFormItemError(false);
                isItemError = false;
            }
    
            if(!formDescription.trim()) {
                setFormDescriptionError(true);
                isDescriptionError = true;
            } else {
                setFormDescriptionError(false);
                isDescriptionError = false;
            }
    
            if(!formNumberOfRatings.trim()) {
                setFormNumberOfRatingsError(true);
                isNumberOfRatingsError = true;
            } else {
                setFormNumberOfRatingsError(false);
                isNumberOfRatingsError = false;
            }
    
            if(isItemError || isDescriptionError || isNumberOfRatingsError) {
                return;
            }
    
            const checkListIndicator = new CheckListIndicator();
            checkListIndicator.isNew = true;
            checkListIndicator.id = checkListIndicatorList.length + 1;
            checkListIndicator.itemNr = (checkListIndicatorList.length + 1).toString();
            checkListIndicator.item = formItem;
            checkListIndicator.description = formDescription;
            checkListIndicator.numberOfRatings = formNumberOfRatings;
            checkListIndicator.weight = formWeight;
    
            setCheckListIndicatorList([...checkListIndicatorList, checkListIndicator]);
            handleCheckListIndicatorModalClose();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Indicator added successfully");

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error saving indicator");
        }
    }

    const handleCheckListSpecificIndicatorSave = async () => {
       try {
        let isSpecificItemError = false;
        let isSpecificDescriptionError = false;
        let isSpecificNumberOfRatingsError = false;

        if(!formSpecificItem.trim()) {
            setFormSpecificItemError(true);
            isSpecificItemError = true;
        } else {
            setFormSpecificItemError(false);
            isSpecificItemError = false;
        }

        if(!formSpecificDescription.trim()) {
            setFormSpecificDescriptionError(true);
            isSpecificDescriptionError = true;
        } else {
            setFormSpecificDescriptionError(false);
            isSpecificDescriptionError = false;
        }

        if(!formSpecificNumberOfRatings.trim()) {
            setFormSpecificNumberOfRatingsError(true);
            isSpecificNumberOfRatingsError = true;
        } else {
            setFormSpecificNumberOfRatingsError(false);
            isSpecificNumberOfRatingsError = false;
        }

        if(isSpecificItemError || isSpecificDescriptionError || isSpecificNumberOfRatingsError) {
            return;
        }

        const checkListSpecificIndicator = new CheckListSpecificIndicator();
        checkListSpecificIndicator.isNew = true;
        checkListSpecificIndicator.id = checkListSpecificIndicatorList.length + 1;
        checkListSpecificIndicator.item = formSpecificItem;
        checkListSpecificIndicator.description = formSpecificDescription;
        checkListSpecificIndicator.numberOfRatings = formSpecificNumberOfRatings;
        checkListSpecificIndicator.weight = formSpecificWeight;

        const checkListIndicator = new CheckListIndicator();
        checkListIndicator.id = indicatorIdSelectedRow;
        const selectedIndicator = checkListIndicatorList.find(indicator => indicator.id === indicatorIdSelectedRow);

        const specificIndicatorsForSelected = checkListSpecificIndicatorList.filter(
            specInd => specInd.checkListIndicator.id === indicatorIdSelectedRow
        );

        const specificIndicatorItemNr = (specificIndicatorsForSelected.length + 1).toString();

        checkListSpecificIndicator.itemNr = `${selectedIndicator.itemNr}.${specificIndicatorItemNr}`;

        checkListSpecificIndicator.checkListIndicator = checkListIndicator;

        setCheckListSpecificIndicatorList([...checkListSpecificIndicatorList, checkListSpecificIndicator]);
        handleCheckListSpecificIndicatorModalClose();
        setOpen(true);
        setTypeAlert("success");
        setMessageAlert("SpecificIndicator added successfully");

       } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error adding specificIndicator");
       }
    }

    const handleCheckListRateSave = async () => {
        try {
            let isRateDescriptionError = false;
            let isRateExtendedDescriptionError = false;
    
            if(!formRateDescription.trim()) {
                setFormRateDescriptionError(true);
                isRateDescriptionError = true;
            } else {
                setFormRateDescriptionError(false);
                isRateDescriptionError = false;
            }
    
            if(!formRateExtendedDescription.trim()) {
                setFormRateExtendedDescriptionError(true);
                isRateExtendedDescriptionError = true;
            } else {
                setFormRateExtendedDescriptionError(false);
                isRateExtendedDescriptionError = false;
            }
    
            if(isRateDescriptionError || isRateExtendedDescriptionError) {
                return;
            }
    
            const checkListRate = new CheckListRate();
            checkListRate.isNew = true;
            checkListRate.id = checkListRateList.length + 1;
            checkListRate.rate = formRateRate;
            checkListRate.description = formRateDescription;
            checkListRate.extendedDescription = formRateExtendedDescription;
    
            if(specificIndicatorIdSelectedRow > 0) {
                const checkListSpecificIndicator = new CheckListSpecificIndicator();
                checkListSpecificIndicator.id = specificIndicatorIdSelectedRow;
                checkListRate.checkListSpecificIndicator = checkListSpecificIndicator;
            } else {
                const checkListIndicator = new CheckListIndicator();
                checkListIndicator.id = indicatorIdSelectedRow;
                checkListRate.checkListIndicator = checkListIndicator;
            }
    
            setCheckListRateList([...checkListRateList, checkListRate]);
            handleCheckListRateModalClose();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Rate added successfully");

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error adding rate");
        }
    }

    const handleCheckListIndicatorUpdate = async () => {
       try {
        const checkListIndicator = new CheckListIndicator();
        checkListIndicator.id = formId;
        checkListIndicator.item = formItem;
        checkListIndicator.description = formDescription;
        checkListIndicator.numberOfRatings = formNumberOfRatings;
        checkListIndicator.weight = formWeight;

        let isItemError = false;
        let isDescriptionError = false;
        let isNumberOfRatingsError = false;

        if(!formItem.trim()) {
            setFormItemError(true);
            isItemError = true;
        } else {
            setFormItemError(false);
            isItemError = false;
        }

        if(!formDescription.trim()) {
            setFormDescriptionError(true);
            isDescriptionError = true;
        } else {
            setFormDescriptionError(false);
            isDescriptionError = false;
        }

        if(formNumberOfRatings < 1) {
            setFormNumberOfRatingsError(true);
            isNumberOfRatingsError = true;
        } else {
            setFormNumberOfRatingsError(false);
            isNumberOfRatingsError = false;
        }

        if(isItemError || isDescriptionError || isNumberOfRatingsError) {
            return;
        }

        const checkListIndicatorIndex = checkListIndicatorList.findIndex((checkListIndicator) => checkListIndicator.id === formId);

        checkListIndicatorList[checkListIndicatorIndex].item = checkListIndicator.item;
        checkListIndicatorList[checkListIndicatorIndex].description = checkListIndicator.description;
        checkListIndicatorList[checkListIndicatorIndex].numberOfRatings = checkListIndicator.numberOfRatings;
        checkListIndicatorList[checkListIndicatorIndex].weight = checkListIndicator.weight;

        setCheckListIndicatorList(checkListIndicatorList);
        handleCheckListIndicatorModalClose();
        setOpen(true);
        setTypeAlert("success");
        setMessageAlert("Indicator updated successfully");

       } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating indicator");
       }
    }

    const handleCheckListSpecificIndicatorUpdate = async () => {
        try {
            const checkListSpecificIndicator = new CheckListSpecificIndicator();
            checkListSpecificIndicator.id = formSpecificId;
            checkListSpecificIndicator.item = formSpecificItem;
            checkListSpecificIndicator.description = formSpecificDescription;
            checkListSpecificIndicator.numberOfRatings = formSpecificNumberOfRatings;
            checkListSpecificIndicator.weight = formSpecificWeight;

            let isSpecificItemError = false;
            let isSpecificDescriptionError = false;

            if(!formSpecificItem.trim()) {
                setFormSpecificItemError(true);
                isSpecificItemError = true;
            } else {
                setFormSpecificItemError(false);
                isSpecificItemError = false;
            }

            if(!formSpecificDescription.trim()) {
                setFormSpecificDescriptionError(true);
                isSpecificDescriptionError = true;
            } else {
                setFormSpecificDescriptionError(false);
                isSpecificDescriptionError = false;
            }

            if(isSpecificItemError || isSpecificDescriptionError) {
                return;
            }

            const checkListSpecificIndicatorIndex = checkListSpecificIndicatorList.findIndex((checkListSpecificIndicator) => checkListSpecificIndicator.id === formSpecificId);

            checkListSpecificIndicatorList[checkListSpecificIndicatorIndex].item = checkListSpecificIndicator.item;
            checkListSpecificIndicatorList[checkListSpecificIndicatorIndex].description = checkListSpecificIndicator.description;
            checkListSpecificIndicatorList[checkListSpecificIndicatorIndex].weight = checkListSpecificIndicator.weight;
            checkListSpecificIndicatorList[checkListSpecificIndicatorIndex].numberOfRatings = checkListSpecificIndicator.numberOfRatings;

            setCheckListSpecificIndicatorList(checkListSpecificIndicatorList);
            handleCheckListSpecificIndicatorModalClose();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("SpecificIndicator updated successfully");

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating specificIndicator");
        }
    }

    const handleCheckListRateUpdate = async () => {
       try {
        const checkListRate = new CheckListRate();
        checkListRate.id = formRateId;
        checkListRate.rate = formRateRate;
        checkListRate.description = formRateDescription;
        checkListRate.extendedDescription = formRateExtendedDescription;

        let isRateDescriptionError = false;
        let isRateExtendedDescriptionError = false;

        if(!formRateDescription.trim()) {
            setFormRateDescriptionError(true);
            isRateDescriptionError = true;
        } else {
            setFormRateDescriptionError(false);
            isRateDescriptionError = false;
        }

        if(!formRateExtendedDescription.trim()) {
            setFormRateExtendedDescriptionError(true);
            isRateExtendedDescriptionError = true;
        } else {
            setFormRateExtendedDescriptionError(false);
            isRateExtendedDescriptionError = false;
        }

        if(isRateDescriptionError || isRateExtendedDescriptionError) {
            return;
        }

        const rateIndex = checkListRateList.findIndex((rate) => rate.id === formRateId);

        checkListRateList[rateIndex].rate = checkListRate.rate;
        checkListRateList[rateIndex].description = checkListRate.description;
        checkListRateList[rateIndex].extendedDescription = checkListRate.extendedDescription;

        setCheckListRateList(checkListRateList);
        handleCheckListRateModalClose();
        setOpen(true);
        setTypeAlert("success");
        setMessageAlert("Rate updated successfully");

       } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating rate");
       }
    }

    const clearCheckListIndicatorForm = () => {
        setFormId('');
        setFormItem('');
        setFormDescription('');
        setFormNumberOfRatings('');
        setFormWeight('');
        setFormItemError(false);
        setFormDescriptionError(false);
        setFormNumberOfRatingsError(false);
    }

    const clearCheckListSpecificIndicatorForm = () => {
        setFormSpecificId('');
        setFormSpecificItem('');
        setFormSpecificDescription('');
        setFormSpecificNumberOfRatings('');
        setFormSpecificItemError(false);
        setFormSpecificDescriptionError(false);
        setFormSpecificNumberOfRatingsError(false);
    }

    const clearCheckListRateForm = () => {
        setFormRateId('');
        setFormRateRate('');
        setFormRateDescription('');
        setFormRateExtendedDescription('');
        setFormRateRateError(false);
        setFormRateDescriptionError(false);
        setFormRateExtendedDescriptionError(false);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const checkListIndicatorColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'item',
            headerName: 'Item',
            width: 100
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 600
        },
        {
            field: 'numberOfRatings',
            headerName: 'Number Of Ratings',
            width: 150
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 150
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => updateCheckListIndicator(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => handleDeleteModalOpen(params.row)} />
                </div>
            )
        }
    ];

    const updateCheckListIndicator = (checkListIndicator) => {
        setFormId(checkListIndicator.id);
        setFormItem(checkListIndicator.item);
        setFormDescription(checkListIndicator.description);
        setFormNumberOfRatings(checkListIndicator.numberOfRatings);
        setFormWeight(checkListIndicator.weight);
        setIsCheckListIndicatorModalEditOpen(true);
        setIsCheckListIndicatorModalOpen(true);
    }

    const removeCheckListIndicator = () => {
        try {
            let deletedCheckListIndicator = checkListIndicatorList.filter(indicator => indicator.id === deleteSelectedIndicatorId)[0];
            let deletedCheckListSpecificIndicator = checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === deletedCheckListIndicator.id);
            let tempDeletedCheckListRate = [];
            deletedCheckListSpecificIndicator.forEach((specificIndicator) => {
                let tempCheckListRate = checkListRateList.filter(rate => rate.checkListSpecificIndicator !== undefined);
                let filteredTempCheckListRate = tempCheckListRate.filter(rate => rate.checkListSpecificIndicator.id === specificIndicator.id);
                filteredTempCheckListRate.forEach(rate => {
                    tempDeletedCheckListRate.push(rate)
                });
            });
            let deletedCheckListRate = tempDeletedCheckListRate.reduce((acc, current) => {
                const exists = acc.some(item => item.id === current.id);
                return exists ? acc : [...acc, current];
            }, []);
            deletedCheckListRate.concat(checkListRateList.filter(rate => rate.checkListIndicator !== undefined && deletedCheckListIndicator.id === rate.checkListIndicator.id));
            deletedCheckListSpecificIndicator.forEach(specificIndicator => {
                removeCheckListSpecificIndicator(specificIndicator.id)
            });
            deletedCheckListRate.map((rate) => removeCheckListRate(rate, false));
    
            fetch(ApiUrl.checkListIndicatorEndpoint + "/" + deleteSelectedIndicatorId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(handleDeleteModalClose)
            .catch(error => {
                console.log(error);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Error method delete");
            })
    
            let tempCheckListIndicatorList = checkListIndicatorList.filter(tempCheckListIndicator => tempCheckListIndicator.id !== deleteSelectedIndicatorId);
            setCheckListIndicatorList(tempCheckListIndicatorList);
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Indicator deleted successfully")

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error deleting indicator")
        }
    }

    const checkListSpecificIndicatorColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'item',
            headerName: 'Item',
            width: 100
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 600
        },
        {
            field: 'numberOfRatings',
            headerName: 'Number Of Ratings',
            width: 150
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 150
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => updateCheckListSpecificIndicator(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => handleDeleteSpecificModalOpen(params.row)} />
                </div>
            )
        }
    ];

    const updateCheckListSpecificIndicator = (checkListSpecificIndicator) => {
        setFormSpecificId(checkListSpecificIndicator.id);
        setFormSpecificItem(checkListSpecificIndicator.item);
        setFormSpecificDescription(checkListSpecificIndicator.description);
        setFormSpecificNumberOfRatings(checkListSpecificIndicator.numberOfRatings);
        setFormSpecificWeight(checkListSpecificIndicator.weight);
        setIsCheckListSpecificIndicatorModalEditOpen(true);
        setIsCheckListSpecificIndicatorModalOpen(true);
    }

    const removeCheckListSpecificIndicator = (specificIndicatorId) => {
        try {
            let deletedCheckListSpecificIndicator;
            if (specificIndicatorId !== undefined) {
                deletedCheckListSpecificIndicator = checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.id === specificIndicatorId)[0];
            } else {
                deletedCheckListSpecificIndicator = checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.id === deleteSelectedSpecificIndicatorId)[0];
            }
            let tempDeletedCheckListRate = [];
            let tempCheckListRate = checkListRateList.filter(rate => rate.checkListSpecificIndicator !== undefined);
            let filteredTempCheckListRate = tempCheckListRate.filter(rate => rate.checkListSpecificIndicator.id === deletedCheckListSpecificIndicator.id);
            filteredTempCheckListRate.forEach(rate => {
                tempDeletedCheckListRate.push(rate)
            });
            let deletedCheckListRate = tempDeletedCheckListRate.reduce((acc, current) => {
                const exists = acc.some(item => item.id === current.id);
                return exists ? acc : [...acc, current];
            }, []);
            deletedCheckListRate.map((rate) => removeCheckListRate(rate, false));

            fetch(ApiUrl.checkListSpecificIndicatorEndpoint + "/" + deleteSelectedSpecificIndicatorId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(handleDeleteSpecificModalClose)
            .catch(error => {
                console.log(error);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Error method delete specificIndicator");
            })

            let tempCheckListSpecificIndicatorList = checkListSpecificIndicatorList.filter(tempCheckListSpecificIndicator =>
                tempCheckListSpecificIndicator.id !== deleteSelectedSpecificIndicatorId);
            setCheckListSpecificIndicatorList(tempCheckListSpecificIndicatorList);
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("SpecificIndicator added successfully");

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error deleting specificIndicator");
        }
    }

    const checkListRateColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'rate',
            headerName: 'Rate',
            width: 100
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300
        },
        {
            field: 'extendedDescription',
            headerName: 'Extended Description',
            width: 600
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => updateCheckListRate(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => removeCheckListRate(params.row, true)} />
                </div>
            )
        }
    ];

    const updateCheckListRate = (checkListRate) => {
        setFormRateId(checkListRate.id);
        setFormRateRate(checkListRate.rate);
        setFormRateDescription(checkListRate.description);
        setFormRateExtendedDescription(checkListRate.extendedDescription);
        setIsCheckListRateModalEditOpen(true);
        setIsCheckListRateModalOpen(true);
    }

    const removeCheckListRate = (checkListRate, deleteApi) => {
        try {            
            if (deleteApi) {
                fetch(ApiUrl.checkListRateEndpoint, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(checkListRate),
                })
                .catch(error => {
                    console.log(error);
                    setOpen(true);
                    setTypeAlert("error");
                    setMessageAlert("Error method delete");
                })
            }
            let tempCheckListRateList = checkListRateList.filter(tempCheckListRate => tempCheckListRate.id !== checkListRate.id);
            setCheckListRateList(tempCheckListRateList);
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Rate deleted successfully");

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error deleting rate");
        }
    }

    const [disableAddRate, setDisableAddRate] = useState(false);
    const [disableAddSpecIndi, setDisableAddSpecIndi] = useState(false);

    const handleIndicatorRowSelected = (row) => {
        const tempCheckListSpecificIndicator = checkListSpecificIndicatorList.reduce((acc, current) => {
            const exists = acc.some(item => item.id === current.id);
            return exists ? acc : [...acc, current];
        }, [])
        setCheckListSpecificIndicatorList(tempCheckListSpecificIndicator);

        const specIndiExist = checkListSpecificIndicatorList.some(x => x.checkListIndicator.id === row[0]);
        if (specIndiExist) {
            setDisableAddRate(true)
        } else {
            setDisableAddRate(false)
        }

        const rateIndiExist = checkListRateList.some(x => x.checkListIndicator.id === row[0])
        if (rateIndiExist) {
            setDisableAddSpecIndi(true)
        } else {
            setDisableAddSpecIndi(false)
        }

        const tempCheckListRate = checkListRateList.reduce((acc, current) => {
            const exists = acc.some(item => item.id === current.id);
            return exists ? acc : [...acc, current];
        }, [])
        setCheckListRateList(tempCheckListRate);
        setIndicatorIdSelectedRow(row[0]);
        setSpecificIndicatorIdSelectedRow(0);
    }

    const handleSpecificIndicatorRowSelected = (row) => {
        setDisableAddRate(false)
        setDisableAddSpecIndi(false)
        setSpecificIndicatorIdSelectedRow(row[0])
    }

    return (
        <Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={typeAlert}>
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Button variant="contained" disableElevation onClick={navigateBack}>
                    <ArrowBackIcon />
                </Button>
                <Typography variant="h4" style={{ marginLeft: 30 }}>Edit this CheckList</Typography>
            </Box>
            <Tabs
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                centered
                sx={{
                    '.MuiTab-root': { 
                      color: 'black', // Text color for all tabs
                      '&.Mui-selected': { // Styles for the selected tab
                        backgroundColor: '#1976d2', // Background color for the selected tab
                        color: 'white', // Text color for the selected tab
                      },
                    },
                }}
                style={{ marginBottom: 20 }}
            >
                <Tab label="TYPE A" style={{ marginRight: 20 }} />
                <Tab label="TYPE B" style={{ marginRight: 20 }} />
                <Tab label="TYPE C" />
            </Tabs>

            <Typography variant="h6" style={{ marginBottom: 5 }}>Header</Typography>
            <Paper elevation={0} style={{ padding: "15px", border: "1px solid lightgray" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Created by</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ width: 100 }}>
                                    <TextField
                                        value={"B"}
                                        disabled
                                        label="Type"
                                        margin="normal"
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell style={{ width: 200 }}>
                                    <TextField
                                        error={formGlobalCodeError}
                                        helperText={formGlobalCodeError ? "Code is required" : ""}
                                        label="Code"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formGlobalCode}
                                        onChange={(event) => setFormGlobalCode(event.target.value)}
                                    />
                                </TableCell>
                                <TableCell style={{ width: 200 }}>
                                    <Select 
                                        value={formGlobalSubject}
                                        onChange={(event) => setFormGlobalSubject(event.target.value)}
                                        fullWidth
                                    >
                                        {formGlobalSubject !== "" && <MenuItem value={""}>Reset</MenuItem>}
                                        {formGlobalSubjectList.map((formSubject) => <MenuItem key={formSubject.id} value={formSubject.id}>{formSubject.name}</MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell>{formGlobalCreatedBy}</TableCell>
                                <TableCell style={{ width: 400 }}>
                                    <TextField
                                        error={formGlobalDescriptionError}
                                        helperText={formGlobalDescriptionError ? "Description is required" : ""}
                                        label="Description"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        onChange={(event) => setFormGlobalDescription(event.target.value)}
                                        value={formGlobalDescription}
                                    />
                                </TableCell>
                                <TableCell style={{ width: 250 }}>
                                    <Select
                                        fullWidth
                                        value={checkListSelectedStatus}
                                        onChange={(event) => setCheckListSelectedStatus(event.target.value)}
                                    >
                                        {checkListStatuses.map((status) => <MenuItem key={status} value={status}>{status}</MenuItem>)}
                                    </Select>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="h6" style={{ marginRight: 20 }}>Indicator</Typography>
                <Button variant="contained" size="small" disableElevation onClick={handleCheckListIndicatorModalOpen}>
                    <AddIcon style={{ marginRight: '10px' }} />Add Indicator
                </Button>
            </Box>
            <DataGrid
                style={{ backgroundColor: appColor.white }}
                sx={{ 
                    '& .css-qvtrhg-MuiDataGrid-virtualScroller': { 
                        overflow: 'unset', 
                        minHeight: '50px' 
                    } 
                }}
                rows={checkListIndicatorList}
                columns={checkListIndicatorColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    sorting: {
                        sortModel: [{ field: 'item', sort: 'asc' }],
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    handleIndicatorRowSelected(newRowSelectionModel);
                }}
                columnVisibilityModel={{
                    id: false
                }}
                pageSizeOptions={[10]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
            />

            <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="h6" style={{ marginRight: 20 }}>Specific Indicator</Typography>
                <Button disabled={disableAddSpecIndi} variant="contained" size="small" disableElevation onClick={handleCheckListSpecificIndicatorModalOpen}>
                    <AddIcon style={{ marginRight: '10px' }} />Add Specific Indicator
                </Button>
            </Box>
            <DataGrid
                style={{ backgroundColor: appColor.white }}
                sx={{ 
                    '& .css-qvtrhg-MuiDataGrid-virtualScroller': { 
                        overflow: 'unset', 
                        minHeight: '50px' 
                    } 
                }}
                rows={checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === indicatorIdSelectedRow)}
                columns={checkListSpecificIndicatorColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    sorting: {
                        sortModel: [{ field: 'item', sort: 'asc' }],
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    handleSpecificIndicatorRowSelected(newRowSelectionModel);
                }}
                columnVisibilityModel={{
                    id: false
                }}
                pageSizeOptions={[10]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
            />
            
            <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="h6" style={{ marginRight: 20 }}>Rate</Typography>
                <Button disabled={disableAddRate} variant="contained" size="small" disableElevation onClick={handleCheckListRateModalOpen}>
                    <AddIcon style={{ marginRight: '10px' }} />Add Rate
                </Button>
            </Box>
            <DataGrid
                style={{ backgroundColor: appColor.white }}
                sx={{ 
                    '& .css-qvtrhg-MuiDataGrid-virtualScroller': { 
                        overflow: 'unset', 
                        minHeight: '50px' 
                    } 
                }}
                rows={checkListRateList.filter(rate => rate.checkListSpecificIndicator === undefined ? rate.checkListIndicator.id === indicatorIdSelectedRow : rate.checkListSpecificIndicator.id === specificIndicatorIdSelectedRow)}
                columns={checkListRateColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    sorting: {
                        sortModel: [{ field: 'rate', sort: 'asc' }],
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                columnVisibilityModel={{
                    id: false
                }}
                pageSizeOptions={[10]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick
            />
            
            <LoadingButton 
                style={{marginTop: 40}}
                loading={loadingButton}
                loadingPosition='start' 
                startIcon={<SaveIcon />}
                color='success'
                variant='contained'
                disableElevation
                onClick={handleCheckListSave}
                >
                Update
            </LoadingButton>

            <Modal
                open={isCheckListIndicatorModalOpen}
                onClose={handleCheckListIndicatorModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {isCheckListIndicatorModalEditOpen ?
                        <Typography id="modal-modal-title" variant="h5" component="h2">Edit current Indicator</Typography> :
                        <Typography id="modal-modal-title" variant="h5" component="h2">Add a new Indicator</Typography>
                    }
                    <form>
                        <TextField
                            error={formItemError}
                            helperText={formItemError ? "Item is required" : ""}
                            label="Item"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formItem}
                            onChange={(event) => setFormItem(event.target.value)}
                        />
                        <TextField
                            error={formDescriptionError}
                            helperText={formDescriptionError ? "Description is required" : ""}
                            label="Description"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formDescription}
                            onChange={(event) => setFormDescription(event.target.value)}
                        />
                        <TextField
                            error={formNumberOfRatingsError}
                            helperText={formNumberOfRatingsError ? "Number Of Ratings is required" : ""}
                            label="Number Of Ratings"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formNumberOfRatings}
                            onChange={(event) => setFormNumberOfRatings(event.target.value)}
                        />
                        <TextField
                            type="number"
                            min="0"
                            label="Weight"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formWeight}
                            onChange={(event) => setFormWeight(event.target.value)}
                        />
                        <Box marginTop={3} display="flex" alignItems="center">
                            {isCheckListIndicatorModalEditOpen ?
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListIndicatorUpdate}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Update
                                </Button>
                            :
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListIndicatorSave}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Save
                                </Button>
                            }
                            <Button variant="contained" color="error" disableElevation onClick={handleCheckListIndicatorModalClose}>Close</Button>
                        </Box>
                    </form>
                </Box>
            </Modal>

            <Modal
                open={isCheckListSpecificIndicatorModalOpen}
                onClose={handleCheckListSpecificIndicatorModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {isCheckListSpecificIndicatorModalEditOpen ?
                        <Typography id="modal-modal-title" variant="h5" component="h2">Edit current Specific Indicator</Typography> :
                        <Typography id="modal-modal-title" variant="h5" component="h2">Add a new Specific Indicator</Typography>
                    }
                    <FormControl fullWidth>
                        <TextField
                            error={formSpecificItemError}
                            helperText={formSpecificItemError ? "Item is required" : ""}
                            label="Item"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formSpecificItem}
                            onChange={(event) => setFormSpecificItem(event.target.value)}
                        />
                        <TextField
                            error={formSpecificDescriptionError}
                            helperText={formSpecificDescriptionError ? "Description is required" : ""}
                            label="Description"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formSpecificDescription}
                            onChange={(event) => setFormSpecificDescription(event.target.value)}
                        />
                        <TextField
                            error={formSpecificNumberOfRatingsError}
                            helperText={formSpecificNumberOfRatingsError ? "Number Of Ratings is required" : ""}
                            label="Number Of Ratings"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formSpecificNumberOfRatings}
                            onChange={(event) => setFormSpecificNumberOfRatings(event.target.value)}
                        />
                        <TextField
                            type="number"
                            min="0"
                            label="Weight"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formSpecificWeight}
                            onChange={(event) => setFormSpecificWeight(event.target.value)}
                        />
                        <Box marginTop={3} display="flex" alignItems="center">
                        {isCheckListSpecificIndicatorModalEditOpen ?
                            <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListSpecificIndicatorUpdate}>
                                <SaveIcon style={{ marginRight: '15px' }} />Update
                            </Button>
                            :
                            <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListSpecificIndicatorSave}>
                                <SaveIcon style={{ marginRight: '15px' }} />Save
                            </Button>
                        }
                            <Button variant="contained" color="error" disableElevation onClick={handleCheckListSpecificIndicatorModalClose}>Close</Button>
                        </Box>
                    </FormControl>
                </Box>
            </Modal>

            <Modal
                open={isCheckListRateModalOpen}
                onClose={handleCheckListRateModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {isCheckListRateModalEditOpen ?
                        <Typography id="modal-modal-title" variant="h5" component="h2">Edit current Rate</Typography> :
                        <Typography id="modal-modal-title" variant="h5" component="h2">Add a new Rate</Typography>
                    }
                    <form>
                        <TextField
                            error={formRateRateError}
                            helperText={formRateRateError ? "Rate is required" : ""}
                            label="Rate"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formRateRate}
                            onChange={(event) => setFormRateRate(event.target.value)}
                            type="number"
                            disabled
                        />
                        <TextField
                            error={formRateDescriptionError}
                            helperText={formRateDescriptionError ? "Description is required" : ""}
                            label="Description"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formRateDescription}
                            onChange={(event) => setFormRateDescription(event.target.value)}
                        />
                        <TextField
                            error={formRateExtendedDescriptionError}
                            helperText={formRateExtendedDescriptionError ? "Extended Description is required" : ""}
                            label="Extended Description"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            multiline
                            rows={4}
                            style={{ marginBottom: 15 }}
                            value={formRateExtendedDescription}
                            onChange={(event) => setFormRateExtendedDescription(event.target.value)}
                        />
                        <Box marginTop={3} display="flex" alignItems="center">
                            {isCheckListRateModalEditOpen ?
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListRateUpdate}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Update
                                </Button>
                                :
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListRateSave}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Save
                                </Button>
                            }
                            <Button variant="contained" color="error" disableElevation onClick={handleCheckListRateModalClose}>Close</Button>
                        </Box>
                    </form>
                </Box>
            </Modal>

            <Modal
                open={isWarningModalOpen}
                onClose={handleWarningModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>Warning</Typography>
                    <p>Before saving this CheckList you have to add at least one Indicator and one Rate.</p>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="error" disableElevation onClick={handleWarningModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isWarningIndicatorRowModalOpen}
                onClose={handleWarningIndicatorRowModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>Warning</Typography>
                    <p>Before adding a new Specific Indicator or Rating you have to select one Indicator row.</p>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="error" disableElevation onClick={handleWarningIndicatorRowModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                onClose={handleDeleteModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>This operation will delete sensible data</Typography>
                    <p style={{ marginBottom: 15 }}><strong>[ ! ] Pay attention, this operation will delete sensible data [ ! ]</strong></p>
                    <p>By confirming this operation you will delete all occurrencies of this Indicator data</p>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={removeCheckListIndicator}>
                            <SaveIcon style={{ marginRight: '15px' }} />Confirm
                        </Button>
                        <Button variant="contained" color="error" disableElevation onClick={handleDeleteModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteSpecificModalOpen}
                onClose={handleDeleteSpecificModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>This operation will delete sensible data</Typography>
                    <p style={{ marginBottom: 15 }}><strong>[ ! ] Pay attention, this operation will delete sensible data [ ! ]</strong></p>
                    <p>By confirming this operation you will delete all occurrencies of this Specific Indicator data</p>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={() => removeCheckListSpecificIndicator()}>
                            <SaveIcon style={{ marginRight: '15px' }} />Confirm
                        </Button>
                        <Button variant="contained" color="error" disableElevation onClick={handleDeleteSpecificModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default EditCheckListPage;
