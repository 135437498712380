import {DataGrid} from "@mui/x-data-grid";
import appColor from "../../../Style/appColor";
import React from "react";

const dialogColumns = [
    {
        field: 'itemNumber',
        headerName: 'Item',
        headerClassName: 'theme--header',
        width: 100,
        cellClassName: (params) =>
            params.row.isSpecificIndicator ? 'specific-indicator-color' : 'text-bold'
    },
    {
        field: 'indicatorDescription',
        headerName: 'Indicator',
        headerClassName: 'theme--header',
        width: 387,
        cellClassName: (params) =>
            params.row.isSpecificIndicator ? 'specific-indicator-color' : 'text-bold '
    },
    {
        field: 'weight',
        headerName: 'Weight %',
        headerClassName: 'theme--header',
        width: 150,
        valueFormatter: (params) => `${params.value} %`,
    },
    {
        field: 'avgRating',
        headerName: 'Rating',
        headerClassName: 'theme--header',
        width: 250,
        cellClassName: (params) =>{
            if (params.value >= 1 && params.value < 3) {
                return 'background-color-rate-red';
            }

            if (params.value >= 3 && params.value < 4) {
                return 'background-color-rate-orange';
            }

            if (params.value >= 4) {
                return 'background-color-rate-green';
            }

            return '';
        },
        renderCell: (params) => {
            if((params.value === 0 || !params.value)) {
                return (
                    <span style={{color: 'red'}}>
                            {params.row.id !== 'total' && (
                                <>undefined</>
                            )
                            }
                        </span>
                );
            }

            return  params.value.toFixed(1);
        }

    },
    {
        field: 'avgIndicatorScore',
        headerName: 'Weighted Score',
        headerClassName: 'theme--header',
        width: 250,
        renderCell: (params) => {
            const value = params.value;
            if(value === 0 || value === null || value === undefined) {
                return (
                    <span style={{color: 'red'}}>undefined</span>
                );
            }

            return  Number.isInteger(value) ? value : value.toFixed(2);
        }
    }
]

export default function IndicatorsTable({indicators}) {
    return  (
        <DataGrid
            getRowId={(row) => row.id + row.item}
            style={{backgroundColor: appColor.white, marginBottom: 30}}
            rows={indicators}
            columns={dialogColumns}
            sx={{
                '& .theme--header': {
                    backgroundColor: '#2082b1',
                    color: 'white'
                },
                '& .specific-indicator-color': {
                    color: 'grey',
                    fontWeight: 'bold'
                },
                '& .background-color-rate-red':{
                    backgroundColor: '#FF8585',
                },
                '& .background-color-rate-orange':{
                    backgroundColor: '#FFD1A3',
                },
                '& .background-color-rate-green':{
                    backgroundColor: '#DAF2D0',
                },
                '& .text-bold':{
                    fontWeight: 'bolder'
                }
            }}
        />
    );
}