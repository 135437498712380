export class CheckListIndicator {
    constructor(checklist, item, description, numberOfRatings, weight, id, checkListId, note, isNew, itemNr) {
        this.checklist = checklist;
        this.checkListId = checkListId;
        this.item = item;
        this.description = description;
        this.numberOfRatings = numberOfRatings;
        this.weight = weight;
        this.note = note;
        this.id = id;
        this.isNew = isNew;
        this.itemNr = itemNr;
    }

    static fromJSON(json) {
        return new CheckListIndicator(
            json.checklist,
            json.checkListId,
            json.item,
            json.description,
            json.numberOfRatings,
            json.weight,
            json.id,
            json.note,
            json.isNew,
            json.itemNr
        )
    }
}