import React, { useState, useEffect } from 'react';
import { Modal, FormControl, MenuItem, Select, Box, Typography, Tabs, Tab, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CheckList } from '../../Model/CheckList';
import { CheckListIndicator } from '../../Model/CheckListIndicator';
import { CheckListSpecificIndicator } from '../../Model/CheckListSpecificIndicator';
import { CheckListRate } from '../../Model/CheckListRate';
import { User } from '../../Model/User';
import { ApiUrl } from '../../ApiUrl';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import appColor from '../../Style/appColor';
import { CheckListSubject } from '../../Model/CheckListSubject';
import { useAuth } from "../../Hooks/AuthProvider";
function CustomToolbar() {
    return (
        <GridToolbarContainer>
        </GridToolbarContainer>
    );
}

function CreateCheckListPage() {
    const navigate = useNavigate();
    const auth = useAuth();
    const navigateBack = () => navigate('/libraries-standard');

    const [isCheckListSaving, setIsCheckListSaving] = useState(false);
    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const [selectedTab, setSelectedTab] = useState(1);

    const [typeAlert, setTypeAlert] = useState("");
    const [messageAlert, setMessageAlert] = useState("");


    // CheckList modals
    const [isCheckListIndicatorModalOpen, setIsCheckListIndicatorModalOpen] = useState(false);
    const [isCheckListSpecificIndicatorModalOpen, setIsCheckListSpecificIndicatorModalOpen] = useState(false);
    const [isCheckListRateModalOpen, setIsCheckListRateModalOpen] = useState(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [isWarningIndicatorRowModalOpen, setIsWarningIndicatorRowModalOpen] = useState(false);

    const [isCheckListIndicatorModalEditOpen, setIsCheckListIndicatorModalEditOpen] = useState(false);
    const [isCheckListSpecificIndicatorModalEditOpen, setIsCheckListSpecificIndicatorModalEditOpen] = useState(false);
    const [isCheckListRateModalEditOpen, setIsCheckListRateModalEditOpen] = useState(false);

    const handleCheckListIndicatorModalOpen = () => { setIsCheckListIndicatorModalOpen(true); calculateFormIndicatorWeight(); }
    const handleCheckListIndicatorModalClose = () => {
        setIsCheckListIndicatorModalOpen(false);
        setIsCheckListIndicatorModalEditOpen(false);
        clearCheckListIndicatorForm();
    }
    const handleCheckListSpecificIndicatorModalOpen = () => { if(indicatorIdSelectedRow === 0) { setIsWarningIndicatorRowModalOpen(true); return; } setIsCheckListSpecificIndicatorModalOpen(true); calculateFormSpecificIndicatorWeight(); }
    const handleCheckListSpecificIndicatorModalClose = () => {
        setIsCheckListSpecificIndicatorModalOpen(false);
        setIsCheckListSpecificIndicatorModalEditOpen(false);
        clearCheckListSpecificIndicatorForm();
    }
    const handleCheckListRateModalOpen = () => {
        if(indicatorIdSelectedRow === 0) { setIsWarningIndicatorRowModalOpen(true); return; }
        setIsCheckListRateModalOpen(true);
        const checkListRate = checkListRateList.filter(rate => rate.checkListSpecificIndicator === undefined ? rate.checkListIndicator.id === indicatorIdSelectedRow : rate.checkListSpecificIndicator.id === specificIndicatorIdSelectedRow);
        setFormRateRate(checkListRate.length + 1);
    }
    const handleCheckListRateModalClose = () => {
        setIsCheckListRateModalOpen(false);
        setIsCheckListRateModalEditOpen(false);
        clearCheckListRateForm();
    }
    const handleWarningModalClose = () => setIsWarningModalOpen(false);
    const handleWarningIndicatorRowModalClose = () => setIsWarningIndicatorRowModalOpen(false);

    const calculateFormIndicatorWeight = () => {
        if(checkListIndicatorList.length + 1 === 1) {
            setFormWeight(100);
        } else {
            const formWeight = Math.round((1 / (checkListIndicatorList.length + 1)) * 100);
            setFormWeight(formWeight);
        }
    }
    const calculateFormSpecificIndicatorWeight = () => {
        if(checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === indicatorIdSelectedRow).length + 1 === 1) {
            setFormSpecificWeight(100);
        } else {
            const formWeight = Math.round((1 / (checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === indicatorIdSelectedRow).length + 1)) * 100);
            setFormSpecificWeight(formWeight);
        }
    }

    // CheckList components
    const [checkListIndicatorList, setCheckListIndicatorList] = useState([]);
    const [checkListSpecificIndicatorList, setCheckListSpecificIndicatorList] = useState([]);
    const [checkListRateList, setCheckListRateList] = useState([]);

    // CheckList form fields
    const [formGlobalCode, setFormGlobalCode] = useState("");
    const [formGlobalSubject, setFormGlobalSubject] = useState("");
    const [formGlobalDescription, setFormGlobalDescription] = useState("");
    const [formGlobalSubjectList, setFormGlobalSubjectList] = useState([]);
    
    const [formId, setFormId] = useState("");
    const [formItem, setFormItem] = useState("");
    const [formDescription, setFormDescription] = useState("");
    const [formNumberOfRatings, setFormNumberOfRatings] = useState("");
    const [formWeight, setFormWeight] = useState(0);

    const [formSpecificId, setFormSpecificId] = useState("");
    const [formSpecificItem, setFormSpecificItem] = useState("");
    const [formSpecificDescription, setFormSpecificDescription] = useState("");
    const [formSpecificNumberOfRatings, setFormSpecificNumberOfRatings] = useState("");
    const [formSpecificWeight, setFormSpecificWeight] = useState(0);

    const [formRateId, setFormRateId] = useState("");
    const [formRateRate, setFormRateRate] = useState("");
    const [formRateDescription, setFormRateDescription] = useState("");
    const [formRateExtendedDescription, setFormRateExtendedDescription] = useState("");

    // Selected rows
    const [indicatorIdSelectedRow, setIndicatorIdSelectedRow] = useState(0);
    const [specificIndicatorIdSelectedRow, setSpecificIndicatorIdSelectedRow] = useState(0);

    // CheckList form validations
    const [formGlobalCodeError, setFormGlobalCodeError] = useState(false);
    //const [formGlobalSubjectError, setFormGlobalSubjectError] = useState("");
    const [formGlobalDescriptionError, setFormGlobalDescriptionError] = useState(false);
    
    const [formItemError, setFormItemError] = useState(false);
    const [formDescriptionError, setFormDescriptionError] = useState(false);
    const [formNumberOfRatingsError, setFormNumberOfRatingsError] = useState(false);

    const [formSpecificItemError, setFormSpecificItemError] = useState(false);
    const [formSpecificDescriptionError, setFormSpecificDescriptionError] = useState(false);
    const [formSpecificNumberOfRatingsError, setFormSpecificNumberOfRatingsError] = useState(false);

    const [formRateRateError, setFormRateRateError] = useState(false);
    const [formRateDescriptionError, setFormRateDescriptionError] = useState(false);
    const [formRateExtendedDescriptionError, setFormRateExtendedDescriptionError] = useState(false);

    const getCheckListSubjectList = () => {
        fetch(ApiUrl.subjectEndpoint + "/Type/2")
            .then(response => response.json())
            .then(json => setFormGlobalSubjectList(json))
            .catch(error => console.error(error));
    }

    useEffect(getCheckListSubjectList, []);

    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleCheckListSave = async () => {
        setLoadingButton(true);
        const globalCheckList = new CheckList();
        globalCheckList.status = 1;
        globalCheckList.type = 2;

        const checkListSubject = new CheckListSubject();
        checkListSubject.id = formGlobalSubject;
        
        globalCheckList.subject = checkListSubject;
        globalCheckList.code = formGlobalCode;
        globalCheckList.description = formGlobalDescription;
        if (auth) {
            globalCheckList.createdBy = auth.user?.id;
            const profile =  new User();
            profile.id = auth.user.id;
            globalCheckList.ownerUser = profile;
        }

        let isCodeError = false;
        let isDescriptionError = false;

        if(!formGlobalCode.trim()) {
            setFormGlobalCodeError(true);
            isCodeError = true;
            setLoadingButton(false);
        } else {
            setFormGlobalCodeError(false);
            isCodeError = false;
        }

        if(!formGlobalDescription.trim()) {
            setFormGlobalDescriptionError(true);
            isDescriptionError = true;
            setLoadingButton(false);
        } else {
            setFormGlobalDescriptionError(false);
            isDescriptionError = false;
        }

        if(isCodeError || isDescriptionError) {
            return;
        }

        if (checkListIndicatorList.length === 0 || checkListRateList.length === 0) {
            setIsWarningModalOpen(true);
            return;
        } else if (checkListSpecificIndicatorList.length > 0 && checkListRateList.length === 0) {
            setIsWarningModalOpen(true);
            return;
        }

        setIsCheckListSaving(true);
        fetch(ApiUrl.checkListEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(globalCheckList),
        })
        .then(response => response.json())
        .then(data => globalCheckList.id = data.id)
        .then(_ =>
            checkListIndicatorList.forEach((indicator) => {
                const checklist = new CheckList();
                checklist.id = globalCheckList.id;
                indicator.checklist = checklist;
                if (auth) {
                    indicator.createdBy = auth.user?.id;
                }
                fetch(ApiUrl.checkListIndicatorEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(indicator),
                })
                .then(response => response.json())
                .then(indicatorData => {
                    checkListSpecificIndicatorList.forEach((specificIndicator) => {
                        if(specificIndicator.checkListIndicator !== undefined && specificIndicator.checkListIndicator.id === indicator.id) {
                            specificIndicator.checkListIndicator = new CheckListIndicator();
                            specificIndicator.checkListIndicator.id = indicatorData.id;
                            if (auth) {
                                specificIndicator.createdBy = auth.user?.id;
                            }
                            fetch(ApiUrl.checkListSpecificIndicatorEndpoint, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(specificIndicator),
                            })
                            .then(response => response.json())
                            .then(specificIndicatorData => {
                                checkListRateList.forEach((rate) => {
                                    if(rate.checkListSpecificIndicator !== undefined && rate.checkListSpecificIndicator.id === specificIndicator.id) {
                                        rate.checkListIndicator = null;
                                        rate.checkListSpecificIndicator = new CheckListSpecificIndicator();
                                        rate.checkListSpecificIndicator.id = specificIndicatorData.id;
                                        if (auth) {
                                            rate.createdBy = auth.user?.id;
                                        }

                                        fetch(ApiUrl.checkListRateEndpoint, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify(rate),
                                        })
                                        .catch(error => {
                                            console.log(error);
                                            setOpen(true);
                                            setTypeAlert("error");
                                            setMessageAlert("Error saving checklist");
                                            setLoadingButton(false);
                                        })
                                    }
                                })
                            })
                        }
                    })

                    checkListRateList.forEach((rate) => {
                        if(rate.checkListIndicator !== undefined && rate.checkListIndicator.id === indicator.id) {
                            rate.checkListIndicator = new CheckListIndicator();
                            rate.checkListIndicator.id = indicatorData.id;
                            if (auth) {
                                rate.createdBy = auth.user?.id;
                            }
                            fetch(ApiUrl.checkListRateEndpoint, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(rate),
                            })
                            .catch(error => {
                                console.log(error);
                                setOpen(true);
                                setTypeAlert("error");
                                setMessageAlert("Error adding rate");
                                setLoadingButton(false);
                            })
                        }
                    })
                })
            })
        ).then(() => {
            setTimeout(() => {
                navigateBack();
            }, 2000);
        });

        setOpen(true);
        setTypeAlert("success");
        setMessageAlert("Checklist added successfully! Wait to go back");
        setTimeout(() => {
            setLoadingButton(false);
        }, 2000);
    }

    const handleCheckListIndicatorSave = async () => {
       try {
        let isItemError = false;
        let isDescriptionError = false;
        let isNumberOfRatingsError = false;

        if(!formItem.trim()) {
            setFormItemError(true);
            isItemError = true;
        } else {
            setFormItemError(false);
            isItemError = false;
        }

        if(!formDescription.trim()) {
            setFormDescriptionError(true);
            isDescriptionError = true;
        } else {
            setFormDescriptionError(false);
            isDescriptionError = false;
        }

        if(!formNumberOfRatings.trim()) {
            setFormNumberOfRatingsError(true);
            isNumberOfRatingsError = true;
        } else {
            setFormNumberOfRatingsError(false);
            isNumberOfRatingsError = false;
        }

        if(isItemError || isDescriptionError || isNumberOfRatingsError) {
            return;
        }

        const checkListIndicator = new CheckListIndicator();
        checkListIndicator.id = checkListIndicatorList.length + 1;
        checkListIndicator.itemNr = (checkListIndicatorList.length + 1).toString();
        checkListIndicator.item = formItem;
        checkListIndicator.description = formDescription;
        checkListIndicator.numberOfRatings = formNumberOfRatings;
        checkListIndicator.weight = formWeight;

        setCheckListIndicatorList([...checkListIndicatorList, checkListIndicator]);
        handleCheckListIndicatorModalClose();
        setOpen(true);
        setTypeAlert("success");
        setMessageAlert("Indicator added successfully");
        setLoadingButton(false);

       } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error adding indicator");
            setLoadingButton(false);
       }
    }

    const handleCheckListSpecificIndicatorSave = async () => {
       try {
        let isSpecificItemError = false;
        let isSpecificDescriptionError = false;
        let isSpecificNumberOfRatingsError = false;

        if(!formSpecificItem.trim()) {
            setFormSpecificItemError(true);
            isSpecificItemError = true;
        } else {
            setFormSpecificItemError(false);
            isSpecificItemError = false;
        }

        if(!formSpecificDescription.trim()) {
            setFormSpecificDescriptionError(true);
            isSpecificDescriptionError = true;
        } else {
            setFormSpecificDescriptionError(false);
            isSpecificDescriptionError = false;
        }

        if(!formSpecificNumberOfRatings.trim()) {
            setFormSpecificNumberOfRatingsError(true);
            isSpecificNumberOfRatingsError = true;
        } else {
            setFormSpecificNumberOfRatingsError(false);
            isSpecificNumberOfRatingsError = false;
        }

        if(isSpecificItemError || isSpecificDescriptionError || isSpecificNumberOfRatingsError) {
            return;
        }

        const checkListSpecificIndicator = new CheckListSpecificIndicator();
        checkListSpecificIndicator.id = checkListSpecificIndicatorList.length + 1;
        checkListSpecificIndicator.item = formSpecificItem;
        checkListSpecificIndicator.description = formSpecificDescription;
        checkListSpecificIndicator.numberOfRatings = formSpecificNumberOfRatings;
        checkListSpecificIndicator.weight = formSpecificWeight;

        const checkListIndicator = new CheckListIndicator();
        checkListIndicator.id = indicatorIdSelectedRow;
        const selectedIndicator = checkListIndicatorList.find(indicator => indicator.id === indicatorIdSelectedRow);

        const specificIndicatorsForSelected = checkListSpecificIndicatorList.filter(
            specInd => specInd.checkListIndicator.id === indicatorIdSelectedRow
        );

        const specificIndicatorItemNr = (specificIndicatorsForSelected.length + 1).toString();

        checkListSpecificIndicator.itemNr = `${selectedIndicator.itemNr}.${specificIndicatorItemNr}`;

        checkListSpecificIndicator.checkListIndicator = checkListIndicator;

        setCheckListSpecificIndicatorList([...checkListSpecificIndicatorList, checkListSpecificIndicator]);

        handleCheckListSpecificIndicatorModalClose();
        setOpen(true);
        setTypeAlert("success");
        setMessageAlert("SpecifiIndicator added successfully");
        setLoadingButton(false);

       } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error adding specificIndicator");
            setLoadingButton(false);
       }
    }

    const handleCheckListRateSave = async () => {
        try {
            let isRateDescriptionError = false;
            let isRateExtendedDescriptionError = false;
    
            if(!formRateDescription.trim()) {
                setFormRateDescriptionError(true);
                isRateDescriptionError = true;
            } else {
                setFormRateDescriptionError(false);
                isRateDescriptionError = false;
            }
    
            if(!formRateExtendedDescription.trim()) {
                setFormRateExtendedDescriptionError(true);
                isRateExtendedDescriptionError = true;
            } else {
                setFormRateExtendedDescriptionError(false);
                isRateExtendedDescriptionError = false;
            }
    
            if(isRateDescriptionError || isRateExtendedDescriptionError) {
                return;
            }
    
            const checkListRate = new CheckListRate();
            checkListRate.id = checkListRateList.length + 1;
            checkListRate.rate = formRateRate;
            checkListRate.description = formRateDescription;
            checkListRate.extendedDescription = formRateExtendedDescription;
    
            if(specificIndicatorIdSelectedRow > 0) {
                const checkListSpecificIndicator = new CheckListSpecificIndicator();
                checkListSpecificIndicator.id = specificIndicatorIdSelectedRow;
                checkListRate.checkListSpecificIndicator = checkListSpecificIndicator;
            } else {
                const checkListIndicator = new CheckListIndicator();
                checkListIndicator.id = indicatorIdSelectedRow;
                checkListRate.checkListIndicator = checkListIndicator;
            }
    
            setCheckListRateList([...checkListRateList, checkListRate]);
            handleCheckListRateModalClose();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Rate added successfully");
            setLoadingButton(false);
            
        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error adding rate");
            setLoadingButton(false);
        }
       
    }

    const handleCheckListIndicatorUpdate = async () => {
       try {
        const checkListIndicator = new CheckListIndicator();
        checkListIndicator.id = formId;
        checkListIndicator.item = formItem;
        checkListIndicator.description = formDescription;
        checkListIndicator.numberOfRatings = formNumberOfRatings;
        checkListIndicator.weight = formWeight;

        let isItemError = false;
        let isDescriptionError = false;
        let isNumberOfRatingsError = false;

        if(!formItem.trim()) {
            setFormItemError(true);
            isItemError = true;
        } else {
            setFormItemError(false);
            isItemError = false;
        }

        if(!formDescription.trim()) {
            setFormDescriptionError(true);
            isDescriptionError = true;
        } else {
            setFormDescriptionError(false);
            isDescriptionError = false;
        }

        if(!formNumberOfRatings.trim()) {
            setFormNumberOfRatingsError(true);
            isNumberOfRatingsError = true;
        } else {
            setFormNumberOfRatingsError(false);
            isNumberOfRatingsError = false;
        }

        if(isItemError || isDescriptionError || isNumberOfRatingsError) {
            return;
        }

        const checkListIndicatorIndex = checkListIndicatorList.findIndex((checkListIndicator) => checkListIndicator.id === formId);

        checkListIndicatorList[checkListIndicatorIndex].item = checkListIndicator.item;
        checkListIndicatorList[checkListIndicatorIndex].description = checkListIndicator.description;
        checkListIndicatorList[checkListIndicatorIndex].numberOfRatings = checkListIndicator.numberOfRatings;
        checkListIndicatorList[checkListIndicatorIndex].weight = checkListIndicator.weight;

        setCheckListIndicatorList(checkListIndicatorList);
        handleCheckListIndicatorModalClose();
        setOpen(true);
        setTypeAlert("success");
        setMessageAlert("Indicator updated successfully");
        setLoadingButton(false);

       } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating indicator");
            setLoadingButton(false);
       }
    }

    const handleCheckListSpecificIndicatorUpdate = async () => {
        try {
            const checkListSpecificIndicator = new CheckListSpecificIndicator();
            checkListSpecificIndicator.id = formSpecificId;
            checkListSpecificIndicator.item = formSpecificItem;
            checkListSpecificIndicator.description = formSpecificDescription;
            checkListSpecificIndicator.numberOfRatings = formSpecificNumberOfRatings;
            checkListSpecificIndicator.weight = formSpecificWeight;

            let isSpecificItemError = false;
            let isSpecificDescriptionError = false;
            let isNumberOfRatingsError = false;

            if(!formSpecificItem.trim()) {
                setFormSpecificItemError(true);
                isSpecificItemError = true;
            } else {
                setFormSpecificItemError(false);
                isSpecificItemError = false;
            }

            if(!formSpecificDescription.trim()) {
                setFormSpecificDescriptionError(true);
                isSpecificDescriptionError = true;
            } else {
                setFormSpecificDescriptionError(false);
                isSpecificDescriptionError = false;
            }

            if(!formSpecificNumberOfRatings.trim()) {
                setFormNumberOfRatingsError(true);
                isNumberOfRatingsError = true;
            } else {
                setFormNumberOfRatingsError(false);
                isNumberOfRatingsError = false;
            }

            if(isSpecificItemError || isSpecificDescriptionError || isNumberOfRatingsError) {
                return;
            }

            const checkListSpecificIndicatorIndex = checkListSpecificIndicatorList.findIndex((checkListSpecificIndicator) => checkListSpecificIndicator.id === formSpecificId);

            checkListSpecificIndicatorList[checkListSpecificIndicatorIndex].item = checkListSpecificIndicator.item;
            checkListSpecificIndicatorList[checkListSpecificIndicatorIndex].description = checkListSpecificIndicator.description;
            checkListSpecificIndicatorList[checkListSpecificIndicatorIndex].weight = checkListSpecificIndicator.weight;
            checkListSpecificIndicatorList[checkListSpecificIndicatorIndex].numberOfRatings = checkListSpecificIndicator.numberOfRatings;

            setCheckListSpecificIndicatorList(checkListSpecificIndicatorList);
            handleCheckListSpecificIndicatorModalClose();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("SpecificIndicator updated successfully");
            setLoadingButton(false);

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating specificIndicator");
            setLoadingButton(false);
        }
    }

    const handleCheckListRateUpdate = async () => {
        try {
            const checkListRate = new CheckListRate();
            checkListRate.id = formRateId;
            checkListRate.rate = formRateRate;
            checkListRate.description = formRateDescription;
            checkListRate.extendedDescription = formRateExtendedDescription;

            let isRateDescriptionError = false;
            let isRateExtendedDescriptionError = false;

            if(!formRateDescription.trim()) {
                setFormRateDescriptionError(true);
                isRateDescriptionError = true;
            } else {
                setFormRateDescriptionError(false);
                isRateDescriptionError = false;
            }

            if(!formRateExtendedDescription.trim()) {
                setFormRateExtendedDescriptionError(true);
                isRateExtendedDescriptionError = true;
            } else {
                setFormRateExtendedDescriptionError(false);
                isRateExtendedDescriptionError = false;
            }

            if(isRateDescriptionError || isRateExtendedDescriptionError) {
                return;
            }

            const rateIndex = checkListRateList.findIndex((rate) => rate.id === formRateId);

            checkListRateList[rateIndex].rate = checkListRate.rate;
            checkListRateList[rateIndex].description = checkListRate.description;
            checkListRateList[rateIndex].extendedDescription = checkListRate.extendedDescription;

            setCheckListRateList(checkListRateList);
            handleCheckListRateModalClose();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Rate updated successfully");
            setLoadingButton(false);

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating rate");
            setLoadingButton(false);
        }
    }

    const clearCheckListIndicatorForm = () => {
        setFormId('');
        setFormItem('');
        setFormDescription('');
        setFormNumberOfRatings('');
        setFormWeight('');
        setFormItemError(false);
        setFormDescriptionError(false);
        setFormNumberOfRatingsError(false);
    }

    const clearCheckListSpecificIndicatorForm = () => {
        setFormSpecificId('');
        setFormSpecificItem('');
        setFormSpecificDescription('');
        setFormSpecificNumberOfRatings('');
        setFormSpecificItemError(false);
        setFormSpecificDescriptionError(false);
        setFormSpecificNumberOfRatingsError(false);
    }

    const clearCheckListRateForm = () => {
        setFormRateId('');
        setFormRateRate('');
        setFormRateDescription('');
        setFormRateExtendedDescription('');
        setFormRateRateError(false);
        setFormRateDescriptionError(false);
        setFormRateExtendedDescriptionError(false);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const checkListIndicatorColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'item',
            headerName: 'Item',
            width: 100
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 600
        },
        {
            field: 'numberOfRatings',
            headerName: 'Number Of Ratings',
            width: 150
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 150
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => updateCheckListIndicator(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => removeCheckListIndicator(params.row)} />
                </div>
            )
        }
    ];
    const updateCheckListIndicator = (checkListIndicator) => {
        setFormId(checkListIndicator.id);
        setFormItem(checkListIndicator.item);
        setFormDescription(checkListIndicator.description);
        setFormNumberOfRatings(checkListIndicator.numberOfRatings);
        setFormWeight(checkListIndicator.weight);
        setIsCheckListIndicatorModalEditOpen(true);
        setIsCheckListIndicatorModalOpen(true);
    }
    const removeCheckListIndicator = (checkListIndicator) => {
        try {
            let deletedCheckListSpecificIndicator = checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === checkListIndicator.id);
            let tempDeletedCheckListRate = [];
            deletedCheckListSpecificIndicator.forEach((specificIndicator) => {
                let tempCheckListRate = checkListRateList.filter(rate => rate.checkListSpecificIndicator !== undefined);
                let filteredTempCheckListRate = tempCheckListRate.filter(rate => rate.checkListSpecificIndicator.id === specificIndicator.id);
                filteredTempCheckListRate.map((rate) => tempDeletedCheckListRate.push(rate));
            });
            let deletedCheckListRate = tempDeletedCheckListRate.reduce((acc, current) => {
                const exists = acc.some(item => item.id === current.id);
                return exists ? acc : [...acc, current];
            }, []);
            deletedCheckListRate.concat(checkListRateList.filter(rate => rate.checkListIndicator !== undefined && checkListIndicator.id === rate.checkListIndicator.id));
    
            deletedCheckListSpecificIndicator.map((specificIndicator) => removeCheckListSpecificIndicator(specificIndicator.id));
            deletedCheckListRate.map((rate) => removeCheckListRate(rate));
    
            let tempCheckListIndicatorList = checkListIndicatorList.filter(tempCheckListIndicator => tempCheckListIndicator.id !== checkListIndicator.id);
            setCheckListIndicatorList(tempCheckListIndicatorList);
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Indicator deleted successfully");
            setLoadingButton(false);

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error deleting Indicator");
            setLoadingButton(false);
        }
    }

    const checkListSpecificIndicatorColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'item',
            headerName: 'Item',
            width: 100
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 600
        },
        {
            field: 'numberOfRatings',
            headerName: 'Number Of Ratings',
            width: 150
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 150
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => updateCheckListSpecificIndicator(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => removeCheckListSpecificIndicator(params.row)} />
                </div>
            )
        }
    ];
    const updateCheckListSpecificIndicator = (checkListSpecificIndicator) => {
        setFormSpecificId(checkListSpecificIndicator.id);
        setFormSpecificItem(checkListSpecificIndicator.item);
        setFormSpecificDescription(checkListSpecificIndicator.description);
        setFormSpecificNumberOfRatings(checkListSpecificIndicator.numberOfRatings);
        setIsCheckListSpecificIndicatorModalEditOpen(true);
        setIsCheckListSpecificIndicatorModalOpen(true);
    }
    const removeCheckListSpecificIndicator = (checkListSpecificIndicator) => {
        try {
            let tempDeletedCheckListRate = [];
            let tempCheckListRate = checkListRateList.filter(rate => rate.checkListSpecificIndicator !== undefined);
            let filteredTempCheckListRate = tempCheckListRate.filter(rate => rate.checkListSpecificIndicator.id === checkListSpecificIndicator.id);
            filteredTempCheckListRate.forEach((rate) => tempDeletedCheckListRate.push(rate));
            let deletedCheckListRate = tempDeletedCheckListRate.reduce((acc, current) => {
                const exists = acc.some(item => item.id === current.id);
                return exists ? acc : [...acc, current];
            }, []);
            deletedCheckListRate.map((rate) => removeCheckListRate(rate));
    
            let tempCheckListSpecificIndicatorList = checkListSpecificIndicatorList.filter(tempCheckListSpecificIndicator =>
                tempCheckListSpecificIndicator.id !== checkListSpecificIndicator.id);
            setCheckListSpecificIndicatorList(tempCheckListSpecificIndicatorList);
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("SpecificIndicator deleted successfully");
            setLoadingButton(false);

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error deleting specificIndicator");
            setLoadingButton(false);
        }
    }

    const checkListRateColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'rate',
            headerName: 'Rate',
            width: 100
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300
        },
        {
            field: 'extendedDescription',
            headerName: 'Extended Description',
            width: 600
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => updateCheckListRate(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => removeCheckListRate(params.row)} />
                </div>
            )
        }
    ];
    const updateCheckListRate = (checkListRate) => {
        setFormRateId(checkListRate.id);
        setFormRateRate(checkListRate.rate);
        setFormRateDescription(checkListRate.description);
        setFormRateExtendedDescription(checkListRate.extendedDescription);
        setIsCheckListRateModalEditOpen(true);
        setIsCheckListRateModalOpen(true);
    }

    const removeCheckListRate = (checkListRate) => {
        try {
            let tempCheckListRateList = checkListRateList.filter(tempCheckListRate => tempCheckListRate.id !== checkListRate.id);
            setCheckListRateList(tempCheckListRateList);
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Rate deleted successfully");
            setLoadingButton(false);

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error deleting rate");
            setLoadingButton(false);
        }
    }

    const [disableAddRate, setDisableAddRate] = useState(false);
    const [disableAddSpecIndi, setDisableAddSpecIndi] = useState(false);
    const handleIndicatorRowSelected = (row) => {
        setIndicatorIdSelectedRow(row[0]);

        const specIndiExist = checkListSpecificIndicatorList.some(x => x.checkListIndicator.id === row[0]);
        if (specIndiExist) {
            setDisableAddRate(true)
        } else {
            setDisableAddRate(false)
        }
        const rateIndiExist = checkListRateList.some(x => x.checkListIndicator.id === row[0])
        if (rateIndiExist) {
            setDisableAddSpecIndi(true)
        } else {
            setDisableAddSpecIndi(false)
        }
        setSpecificIndicatorIdSelectedRow(0);
    }
    const handleSpecificIndicatorRowSelected = (row) => {
        setDisableAddRate(false)
        setDisableAddSpecIndi(false)
        setSpecificIndicatorIdSelectedRow(row[0])
    }

    return (
        <Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={typeAlert}>
                    {messageAlert}
                </Alert>
            </Snackbar>
            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Button variant="contained" disableElevation onClick={navigateBack}>
                    <ArrowBackIcon />
                </Button>
                <Typography variant="h4" style={{ marginLeft: 30 }}>Create new CheckList</Typography>
            </Box>
            <Tabs
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                centered
                sx={{
                    '.MuiTab-root': { 
                      color: 'black', // Text color for all tabs
                      '&.Mui-selected': { // Styles for the selected tab
                        backgroundColor: '#1976d2', // Background color for the selected tab
                        color: 'white', // Text color for the selected tab
                      },
                    },
                }}
                style={{ marginBottom: 20 }}
            >
                <Tab label="TYPE A" style={{ marginRight: 20 }} />
                <Tab label="TYPE B" style={{ marginRight: 20 }} />
                <Tab label="TYPE C" />
            </Tabs>

            <Typography variant="h6" style={{ marginBottom: 5 }}>Header</Typography>
            <Paper elevation={0} style={{ padding: "15px", border: "1px solid lightgray" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Subject</TableCell>
                                {<TableCell>Created by</TableCell>}
                                <TableCell>Description</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ width: 100 }}>
                                    <TextField
                                        value={"B"}
                                        disabled
                                        label="Type"
                                        margin="normal"
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell style={{ width: 200 }}>
                                    <TextField
                                        error={formGlobalCodeError}
                                        helperText={formGlobalCodeError ? "Code is required" : ""}
                                        label="Code"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formGlobalCode}
                                        onChange={(event) => setFormGlobalCode(event.target.value)}
                                    />
                                </TableCell>
                                <TableCell style={{ width: 200 }}>
                                    <Select 
                                        value={formGlobalSubject}
                                        onChange={(event) => setFormGlobalSubject(event.target.value)}
                                        fullWidth
                                    >
                                        {formGlobalSubject !== "" && <MenuItem value={""}>Reset</MenuItem>}
                                        {formGlobalSubjectList.map((formSubject) => <MenuItem key={formSubject.id} value={formSubject.id}>{formSubject.name}</MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell>{auth.user?.name} {auth.user?.surname}</TableCell>
                                <TableCell style={{ width: 400 }}>
                                    <TextField
                                        error={formGlobalDescriptionError}
                                        helperText={formGlobalDescriptionError ? "Description is required" : ""}
                                        label="Description"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        onChange={(event) => setFormGlobalDescription(event.target.value)}
                                        value={formGlobalDescription}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button variant="outlined">DRAFT</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="h6" style={{ marginRight: 20 }}>Indicator</Typography>
                <Button variant="contained" size="small" disableElevation onClick={handleCheckListIndicatorModalOpen}>
                    <AddIcon style={{ marginRight: '10px' }} />Add Indicator
                </Button>
            </Box>
            <DataGrid
                style={{ backgroundColor: appColor.white }}
                sx={{ 
                    '& .css-qvtrhg-MuiDataGrid-virtualScroller': { 
                        overflow: 'unset', 
                        minHeight: '50px' 
                    } 
                }}
                rows={checkListIndicatorList}
                columns={checkListIndicatorColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    handleIndicatorRowSelected(newRowSelectionModel);
                }}
                columnVisibilityModel={{
                    id: false
                }}
                pageSizeOptions={[10]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
            />

            <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="h6" style={{ marginRight: 20 }}>Specific Indicator</Typography>
                <Button disabled={disableAddSpecIndi} variant="contained" size="small" disableElevation onClick={handleCheckListSpecificIndicatorModalOpen}>
                    <AddIcon style={{ marginRight: '10px' }} />Add Specific Indicator
                </Button>
            </Box>
            <DataGrid
                style={{ backgroundColor: appColor.white }}
                sx={{ 
                    '& .css-qvtrhg-MuiDataGrid-virtualScroller': { 
                        overflow: 'unset', 
                        minHeight: '50px' 
                    } 
                }}
                rows={checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === indicatorIdSelectedRow)}
                columns={checkListSpecificIndicatorColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    handleSpecificIndicatorRowSelected(newRowSelectionModel);
                }}
                columnVisibilityModel={{
                    id: false
                }}
                pageSizeOptions={[10]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
            />
            
            <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="h6" style={{ marginRight: 20 }}>Rate</Typography>
                <Button disabled={disableAddRate} variant="contained" size="small" disableElevation onClick={handleCheckListRateModalOpen}>
                    <AddIcon style={{ marginRight: '10px' }} />Add Rate
                </Button>
            </Box>
            <DataGrid
                style={{ backgroundColor: appColor.white }}
                sx={{ 
                    '& .css-qvtrhg-MuiDataGrid-virtualScroller': { 
                        overflow: 'unset', 
                        minHeight: '50px' 
                    } 
                }}
                rows={checkListRateList.filter(rate => rate.checkListSpecificIndicator === undefined ? rate.checkListIndicator.id === indicatorIdSelectedRow : rate.checkListSpecificIndicator.id === specificIndicatorIdSelectedRow)}
                columns={checkListRateColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                columnVisibilityModel={{
                    id: false
                }}
                pageSizeOptions={[10]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick
            />
            
             <LoadingButton 
                style={{marginTop: 40}}
                loading={loadingButton}
                loadingPosition='start' 
                startIcon={<SaveIcon />}
                color='success'
                variant='contained'
                disableElevation
                onClick={handleCheckListSave}
                >
                Update
            </LoadingButton>

            <Modal
                open={isCheckListIndicatorModalOpen}
                onClose={handleCheckListIndicatorModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {isCheckListIndicatorModalEditOpen ?
                        <Typography id="modal-modal-title" variant="h5" component="h2">Edit current Indicator</Typography> :
                        <Typography id="modal-modal-title" variant="h5" component="h2">Add a new Indicator</Typography>
                    }
                    <form>
                        <TextField
                            error={formItemError}
                            helperText={formItemError ? "Item is required" : ""}
                            label="Item"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formItem}
                            onChange={(event) => setFormItem(event.target.value)}
                        />
                        <TextField
                            error={formDescriptionError}
                            helperText={formDescriptionError ? "Description is required" : ""}
                            label="Description"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formDescription}
                            onChange={(event) => setFormDescription(event.target.value)}
                        />
                        <TextField
                            error={formNumberOfRatingsError}
                            helperText={formNumberOfRatingsError ? "Number Of Ratings is required" : ""}
                            label="Number Of Ratings"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formNumberOfRatings}
                            onChange={(event) => setFormNumberOfRatings(event.target.value)}
                        />
                        <TextField
                            type="number"
                            min="0"
                            label="Weight"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formWeight}
                            onChange={(event) => setFormWeight(event.target.value)}
                        />
                        <Box marginTop={3} display="flex" alignItems="center">
                            {isCheckListIndicatorModalEditOpen ?
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListIndicatorUpdate}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Update
                                </Button>
                            :
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListIndicatorSave}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Save
                                </Button>
                            }
                            <Button variant="contained" color="error" disableElevation onClick={handleCheckListIndicatorModalClose}>Close</Button>
                        </Box>
                    </form>
                </Box>
            </Modal>

            <Modal
                open={isCheckListSpecificIndicatorModalOpen}
                onClose={handleCheckListSpecificIndicatorModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {isCheckListSpecificIndicatorModalEditOpen ?
                        <Typography id="modal-modal-title" variant="h5" component="h2">Edit current Specific Indicator</Typography> :
                        <Typography id="modal-modal-title" variant="h5" component="h2">Add a new Specific Indicator</Typography>
                    }
                    <FormControl fullWidth>
                        <TextField
                            error={formSpecificItemError}
                            helperText={formSpecificItemError ? "Item is required" : ""}
                            label="Item"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formSpecificItem}
                            onChange={(event) => setFormSpecificItem(event.target.value)}
                        />
                        <TextField
                            error={formSpecificDescriptionError}
                            helperText={formSpecificDescriptionError ? "Description is required" : ""}
                            label="Description"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formSpecificDescription}
                            onChange={(event) => setFormSpecificDescription(event.target.value)}
                        />
                        <TextField
                            error={formSpecificNumberOfRatingsError}
                            helperText={formSpecificNumberOfRatingsError ? "Number Of Ratings is required" : ""}
                            label="Number Of Ratings"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formSpecificNumberOfRatings}
                            onChange={(event) => setFormSpecificNumberOfRatings(event.target.value)}
                        />
                        <TextField
                            type="number"
                            min="0"
                            label="Weight"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formSpecificWeight}
                            onChange={(event) => setFormSpecificWeight(event.target.value)}
                        />
                        <Box marginTop={3} display="flex" alignItems="center">
                        {isCheckListSpecificIndicatorModalEditOpen ?
                            <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListSpecificIndicatorUpdate}>
                                <SaveIcon style={{ marginRight: '15px' }} />Update
                            </Button>
                            :
                            <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListSpecificIndicatorSave}>
                                <SaveIcon style={{ marginRight: '15px' }} />Save
                            </Button>
                        }
                            <Button variant="contained" color="error" disableElevation onClick={handleCheckListSpecificIndicatorModalClose}>Close</Button>
                        </Box>
                    </FormControl>
                </Box>
            </Modal>

            <Modal
                open={isCheckListRateModalOpen}
                onClose={handleCheckListRateModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {isCheckListRateModalEditOpen ?
                        <Typography id="modal-modal-title" variant="h5" component="h2">Edit current Rate</Typography> :
                        <Typography id="modal-modal-title" variant="h5" component="h2">Add a new Rate</Typography>
                    }
                    <form>
                        <TextField
                            error={formRateRateError}
                            helperText={formRateRateError ? "Rate is required" : ""}
                            label="Rate"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formRateRate}
                            onChange={(event) => setFormRateRate(event.target.value)}
                            type="number"
                            disabled
                        />
                        <TextField
                            error={formRateDescriptionError}
                            helperText={formRateDescriptionError ? "Description is required" : ""}
                            label="Description"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            style={{ marginBottom: 15 }}
                            value={formRateDescription}
                            onChange={(event) => setFormRateDescription(event.target.value)}
                        />
                        <TextField
                            error={formRateExtendedDescriptionError}
                            helperText={formRateExtendedDescriptionError ? "Extended Description is required" : ""}
                            label="Extended Description"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            multiline
                            rows={4}
                            style={{ marginBottom: 15 }}
                            value={formRateExtendedDescription}
                            onChange={(event) => setFormRateExtendedDescription(event.target.value)}
                        />
                        <Box marginTop={3} display="flex" alignItems="center">
                            {isCheckListRateModalEditOpen ?
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListRateUpdate}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Update
                                </Button>
                                :
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListRateSave}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Save
                                </Button>
                            }
                            <Button variant="contained" color="error" disableElevation onClick={handleCheckListRateModalClose}>Close</Button>
                        </Box>
                    </form>
                </Box>
            </Modal>

            <Modal
                open={isWarningModalOpen}
                onClose={handleWarningModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>Warning</Typography>
                    <p>Before saving this CheckList you have to add at least one Indicator and one Rate.</p>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="error" disableElevation onClick={handleWarningModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isWarningIndicatorRowModalOpen}
                onClose={handleWarningIndicatorRowModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>Warning</Typography>
                    <p>Before adding a new Specific Indicator or Rating you have to select one Indicator row.</p>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="error" disableElevation onClick={handleWarningIndicatorRowModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default CreateCheckListPage;
